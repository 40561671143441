import './Projects.css';
import { Link } from "react-router-dom"
import React from "react";

const Projects = () => {
    return (
        <section id="section-3" className="section-3">  
        <div className="projects-page">
                    <h2 className="projects-header">Projelerim</h2>
                </div>
        <div className="projects-container">
            <Link to='/mobile' >
            <div className="mobile-projects">
            <div>
            <img alt='' src='img/mobile.png' width={200} height={200} />
            </div>
               <h3> Mobil Projelerim</h3>
            </div>
            
        </Link>

        <Link to='/web' >
        <div className="web-projects">
        <div>
            <img alt='' src='img/web.png' width={200} height={200} />
            </div>

               <h3>Web Projelerim</h3> 
            </div>
            </Link>
            </div>
           
        </section>
    )
}

export default Projects