import './Projects.css';
import React from "react";
import webProjectsData from './webProjectsData';
import { Button } from '@mui/material';

const WebPage = () => {
    return (
        <section>
            <div>
                <div className="projects-page">
                    <h2 className="projects-header">Web Projelerim</h2>
                </div>
                <div>
                    {webProjectsData.map((project) => (
                        <div className="projects" key={project.id}>
                            <h3 className="web-projects-name">{project.title}</h3>
                            <a href={project.liveLink} target="_blank" rel="noopener noreferrer" ><img className="web-img" src={project.image} alt={project.title} /></a>
                            <p className="description">{project.description}</p>
                            <div className='projects-container'>
                                <a href={project.githubLink} target="_blank" rel="noopener noreferrer" >
                                    <Button style={{ backgroundColor: 'blueviolet', color: "white" }} variant="contained" >Projeyi GitHub Üzerinde incele</Button>
                                </a>
                                <a href={project.liveLink} target="_blank" rel="noopener noreferrer" >
                                    <Button style={{ backgroundColor: 'blueviolet' }} variant="contained"  >Projeyi Canlıda Gör</Button>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </section>
    )
}

export default WebPage