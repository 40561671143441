import React from 'react';
import GitHubCalendar from 'react-github-calendar';
import './github.css';

const GitHubCalendarComponent = ({ username }) => {
  return (
    <div className="github-calendar-container">
      <GitHubCalendar
        username={username}
        blockSize={12}
        blockMargin={2}
      />
    </div>
  );
};

export default GitHubCalendarComponent;
