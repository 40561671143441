import React from "react";
import "./about.css";

const Experience = () => {
    const experiences = [
        {
            id: 1,
            title: "Software Developer Intern",
            company: "T.C Tarsus Belediyesi",
            date: "Temmuz 2024 - Eylül 2024",
            description: "Belediye içerisinde kullanılacak iki mobil uygulama geliştirme işi",
            logo: "img/tarsusbel.jpg"
        },
        {
            id: 2,
            title: "Junior Mobile Developer",
            company: "Insteption B.V",
            date: "Şubat 2024 - Temmuz 2024",
            description: "Dinamik mobil uygulama geliştirme",
            logo: "img/Insteption.jpg"
        },
        {
            id: 3,
            title: "Founder & Developer",
            company: "Endless Software",
            date: "Ocak 2023 - Halen",
            description: "Arkadaşlarımla kendimizi geliştirmek amacıyla kurmuş olduğumuz start-up",
            logo: "img/endlessLogo.jpg"
        },
        {
            id: 4,
            title: "Vice President And Web Team Lead",
            company: "Tarsus Üniversitesi Genç Bilişimciler Öğrenci Topluluğu",
            date: "Ara 2022 - Halen",
            description: "Tarsus Üniversitesi Genç Bilişimciler Öğrenci Topluluğu Tarsus Üniversitesi",
            logo: "img/gbt.jpg"
        },
        {
            id: 5,
            title: "Junior Software Developer",
            company: "Peliel Software",
            date: "Ocak 2022 - Ağutos 2022",
            description: "Tekbaş grup şirketlerinden olan Peliel Software",
            logo: "img/peliel.png"
        },
        {
            id: 6,
            title: "Online Intern",
            company: "DenizBank",
            date: "Aralık 2022 - Ocak 2023",
            description: "Denizbank online Denizaşırı internship program",
            logo: "img/denizb.png"
        },
    ];

    const groupExperiences = (experiences, groupSize) => {
        const groupedExperiences = [];
        for (let i = 0; i < experiences.length; i += groupSize) {
            groupedExperiences.push(experiences.slice(i, i + groupSize));
        }
        return groupedExperiences;
    };

    const groupedExperiences = groupExperiences(experiences, 2);

    return (
        <div className="experience">
            <h2>Deneyimlerim</h2>
            {groupedExperiences.map((group, index) => (
                <div className="experience-row" key={index}>
                    {group.map((experience) => (
                        <div className="experience-item" key={experience.id} style={{ width: "45%", }}>
                            <img className="experience-logo" src={experience.logo} alt={experience.company} />
                            <div>
                                <h4>{experience.title}</h4>
                                <p>{experience.company}</p>
                                <p>{experience.date}</p>
                                <p>{experience.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Experience;
