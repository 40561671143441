const mobileProjects = [
  {
    id: 1,
    title: "Tarsus Belediyesi Talep Takip Uygulaması",
    image: "https://private-user-images.githubusercontent.com/99503700/367615275-5f23de7f-f9bb-47d0-84af-e928f3979c5b.jpg?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MzE0MjU5MDQsIm5iZiI6MTczMTQyNTYwNCwicGF0aCI6Ii85OTUwMzcwMC8zNjc2MTUyNzUtNWYyM2RlN2YtZjliYi00N2QwLTg0YWYtZTkyOGYzOTc5YzViLmpwZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNDExMTIlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQxMTEyVDE1MzMyNFomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTA0OTk1ZmQ3ZjI2MTJlYzgyOGUzMWRiMTNlNzc5YmE3NTI0YWQ0ODNhMGI1YzBjNzkxYWVhYWQ1ZWU3Mjg1NzQmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0In0.3X0aGyEkc3GgT5k-PbDmdpQEJ6baIBuRACjE0Hn4rB4",
    description: "Tarsus İlçe Belediyesine Gelen Taleplerin Kurum İçin Takibini Sağlayan Mobil Uygulama",
    githubLink: "https://github.com/janberkaltay/"
  },
  {
    id: 1,
    title: "Hayvan Barınaklarındaki Hayvanların Sahiplendirilmesi",
    image: "https://private-user-images.githubusercontent.com/99503700/385349267-f14b6869-49a2-4a2a-a19a-88bcf4822b55.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MzE0MjU4NzMsIm5iZiI6MTczMTQyNTU3MywicGF0aCI6Ii85OTUwMzcwMC8zODUzNDkyNjctZjE0YjY4NjktNDlhMi00YTJhLWExOWEtODhiY2Y0ODIyYjU1LnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFWQ09EWUxTQTUzUFFLNFpBJTJGMjAyNDExMTIlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQxMTEyVDE1MzI1M1omWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTFiNzNjZmE3ODYxMzRkYmE3ZWIxOGEzM2I0Njk4YzQyOWE1ZjM3ZTQ5NGI3YzgyMzM4YjZhYTQ3YzdkZWVjOGUmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0In0.XNk12EEGQ4zyaFXMsjlHi-_wVPw-71wBUC7ctNQ3VwI",
    description: "TÜBİTAK 2209-A Projem Kapsamında Geliştirdiğim Uygulama",
    githubLink: "https://github.com/janberkaltay/"
  },
  {
    id: 1,
    title: "Can Akademi  -  Can Mobil",
    image: "https://github.com/janberkaltay/Flutter-Uplod-and-View-Pdf-From-Firebase/blob/main/Screenshot_20230721_144711.png?raw=true",
    description: "Adana'da bulunan Can Akademi'ye özel",
    githubLink: "https://play.google.com/store/apps/details?id=com.canmobil.can_akademi"
  },
  {
    id: 2,
    title: "QR Kod Tarayıcı",
    image: "https://play-lh.googleusercontent.com/07C5CsxnycZaUQkuFkRvYnGvw2AL6CQeIh22sGBhgDHEPbr7ziRS50jr_04UfxCdY1KL=w2560-h1440-rw",
    description: "QR Kod Tarayıcı",
    githubLink: "https://play.google.com/store/apps/details?id=com.endlesstech.qr_tarayici"
  },
  {
    id: 3,
    title: "Flutter Adana Kent Full AppClone",
    image: "https://user-images.githubusercontent.com/99503700/228924109-967bd66a-ae40-465d-a18b-22d335b4f2b2.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Adana-Akilli-Kent-Full-AppClone"
  },
  {
    id: 4,
    title: "Flutter Basic Link App",
    image: "https://user-images.githubusercontent.com/99503700/237192416-697eb204-ea4a-4e05-9628-2bf9743fa089.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Basic-Link-App"
  },
  {
    id: 5,
    title: "Flutter Dinamik Karşılama Ekranı",
    image: "https://user-images.githubusercontent.com/99503700/230498290-26bdf401-171c-4552-83cd-1f7808c2df66.png",
    description: "Dinamik bir karşılama ekranı",
    githubLink: "https://github.com/janberkaltay/Flutter-Dynamic-Onboarding-Screens"
  },
  {
    id: 6,
    title: "Flutter Gizli Menü",
    image: "https://github.com/janberkaltay/Flutter-Hidden-Drawer-Menu/blob/master/Screenshot_20230314_124943.png?raw=true",
    description: "-",
    githubLink: "https://github.com/janberkaltay/Flutter-Hidden-Drawer-Menu"
  },
  {
    id: 7,
    title: "Onboarding Screens Clone",
    image: "https://github.com/janberkaltay/akilli_kent_Onboarding_screen_clone/blob/master/screenshots/%C4%B1ntro_page_1.png?raw=true",
    description: "-",
    githubLink: "https://github.com/janberkaltay/akilli_kent_Onboarding_screen_clone"
  },
  {
    id: 8,
    title: "Flutter Line Graphics App",
    image: "https://user-images.githubusercontent.com/99503700/260309872-6ed2b654-f15f-47ff-9c12-868cd42a87c2.png",
    description: "-",
    githubLink: "https://github.com/janberkaltay/flutter-graphics"
  },

];

export default mobileProjects;
